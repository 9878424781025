@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  padding-right: 0 !important;
  margin: 0 !important;
  color: #353433 !important;
  font-family: 'Poppins', 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  background-color: '#F0F2F5' !important
}

.scale__container--js {
  text-align: center;
}

.scale--js {
  display: inline-block;
  transform-origin: 50% 0;
  font-family: 'Imbue', serif !important;
  -webkit-font-smoothing: antialiased;
  transform: translate3d(0, 0, 0);
  line-height: 0.9;
}

.sec-scale--js {
  display: inline-block;
  transform-origin: 50% 0;
  /* font-family: 'Imbue', serif !important; */
  -webkit-font-smoothing: antialiased;
  transform: translate3d(0, 0, 0);
  line-height: 0.9;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon{
  color: white !important;
}

.Mui-active .MuiTableSortLabel-icon{
  color: white !important;
}

#category-parent .category-description{
  display: none !important;
}

#category-parent:hover .category-description{
  display: block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important; /* number of lines to show */
  line-clamp: 3  !important; 
  -webkit-box-orient: vertical !important;
}

.cursor-grab {
  cursor: -webkit-grab !important;
  cursor: grab !important;
}

.cursor-grab:active, .cursor-grab:focus {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
}